export function formAction() {
    const formElements = document.querySelectorAll(".dm-form");
    const regExpEmail = /.+@.+\..+/;

    if (formElements.length > 0) {
        for (const form of formElements) {
            const fileInput = form.querySelector("input[type='file']");
            const fileCol = form.querySelector(".dm-form__col--file-upload");
            let uploadValid = true;

            function validateForm() {
                let isValid = true;
                const inputs = form.querySelectorAll("[required]");

                inputs.forEach(function (input, i) {
                    input.addEventListener("focus", () => {
                        input.classList.remove("error");
                    });

                    if (input.type === "text" && input.value.length < 1) {
                        isValid = false;
                        input.classList.add("error");
                    }

                    if (input.type === "textarea" && input.value.length < 10) {
                        isValid = false;
                        input.classList.add("error");
                    }

                    if (input.type === "email" && !input.value.match(regExpEmail)) {
                        isValid = false;
                        input.classList.add("error");
                    }

                    if ((input.type === "checkbox" || input.type == "radio") && !input.checked) {
                        isValid = false;
                        input.classList.add("error");
                    }
                    
                    if(input.type === "select-one" && !input.value) {
                        isValid = false;
                        input.classList.add("error");
                    }
                });
                // console.log(isValid)
                return isValid;
            }

            function validateUpload() {
                if (fileInput && fileInput.files.length > 0) {
                    const imageObject = {};

                    for (const key in fileInput.files[0]) {
                        const value = fileInput.files[0][key];
                        imageObject[key] = value;
                    }

                    return JSON.stringify(imageObject);
                } else {
                    return "";
                }
            }

            // display uploaded file
            if (fileInput) {
                fileInput.addEventListener("change", ({ target }) => {
                    if (document.querySelector(".uploaded-file")) document.querySelector(".uploaded-file").remove();
                    const file = target.files[0];
    
                    const uploadedOutput = document.createElement("div");
                    uploadedOutput.classList.add("uploaded-file");
    
                    uploadedOutput.innerHTML = `
                            <div class="file-type">${file.name.split('.').pop()}</div>
                            <div>
                                <span class="name"><strong>${file.name}</strong></span>
                                <button type="button" aria-label="remove file" data-remove-file>delete file</button>
                            </div>`;
    
                    fileCol.classList.add("has-file");
                    fileCol.querySelector(".default").insertAdjacentElement("afterend", uploadedOutput);
    
                    // check file type and if size > 10mb
                    if (file.type !== "application/pdf" || file.size > 10000000) {
                        uploadValid = false;
                        fileCol.classList.add("error");
                    } else {
                        uploadValid = true;
                        fileCol.classList.remove("error");
                    }
                });
    
                document.addEventListener("click", e => {
                    const removeFileBtn = e.target.closest("[data-remove-file]");
    
                    if (removeFileBtn) {
                        fileInput.value = "";
                        fileCol.classList.remove("has-file", "error");
    
                        document.querySelector(".uploaded-file").remove();
                    }
                });
            }
            

            form.addEventListener("submit", (event) => {
                event.preventDefault();
                const formType = form.getAttribute("data-form-type");

                if (validateForm() && uploadValid) {
                    const url = event.currentTarget.getAttribute("data-url");
                    const formData = new FormData(event.target);
                    const formEntries = Object.fromEntries(formData.entries());

                    if (formEntries.legal === "on") formEntries.legal = true;
                    formEntries.file = validateUpload();

                    // console.log(formEntries);                

                    form.classList.add("submitting");

                    async function postContactForm(data) {
                        try {
                            const response = await fetch(url, {
                                method: "POST",
                                body: data
                            });

                            const result = await response.json();

                            // console.log("Success: ", result);

                            // Print server errors
                            const serverErrors = document.createElement("div");
                            serverErrors.classList.add("dm-form__row", "dm-form__row--errors");

                            if (!result.success) {
                                result.messages.forEach((message) => {
                                    if(formType === "ebook-download" && message === "newsletter.subscribe.already.exists.error") {
                                        form.remove();

                                        document.querySelector(`[data-code="${message}"]`).style.display = "block";
                                        document.querySelector(".dm-download-success").closest(".dm-card").scrollIntoView({behavior: "smooth"});
                                        
                                        downloadEbook();
                                    } else {
                                        if(document.querySelector(`[data-code="${message}"]`)) document.querySelector(`[data-code="${message}"]`).style.display = "block";
                                    }
                                    
                                });
                            } else {
                                form.remove();
                                
                                if(document.querySelector(".dm-form-success")) {
                                    document.querySelector(".dm-form-success").style.display = "block";
                                    document.querySelector(".dm-form-success").closest(".dm-card").scrollIntoView({behavior: "smooth"});
                                }

                                // TRACK FORM SUBMIT
                                if(formType === "contact") {
                                    window.dataLayer.push({
                                        "event": "contact_us",
                                        "event_id": 1.4
                                    });
                                }

                                if(formType === "newsletter") {
                                    window.dataLayer.push({
                                        "event": "newsletter_subscription",
                                        "event_id": 1.1
                                    });
                                }

                                if(formType === "ebook-download") {
                                    window.dataLayer.push({
                                        "event": "select_content",
                                        "event_id": 1.9,
                                        "language": document.documentElement.lang,
                                        "campaign": "Unboxing2025"
                                    });
                                    downloadEbook();
                                }
                            }

                            if (result.error) {
                                serverErrors.innerHTML = `
                                    <div class="dm-form__col">
                                    <p class="error-message" style="display: block;">${result.error.code} error</p>
                                    </div>
                                `;

                                document.querySelector(".dm-form__row--buttons").insertAdjacentElement("afterend", serverErrors)
                            }

                            form.classList.remove("submitting");
                        } catch (error) {
                            console.log("Error: ", error);
                            form.classList.remove("submitting");
                        }
                    } 

                    postContactForm(formData);
                }
            });
        }
    }

    function downloadEbook() {
        const pdfLink = document.querySelector(".dm-hero--ai").getAttribute("data-file");
        const pdfFilename = document.querySelector(".dm-hero--ai").getAttribute("data-filename");

        const link = document.createElement("a");
        link.href = pdfLink;
        link.download = pdfFilename;
        link.click();
    }
}