export function initAIChat() {
    // Global variables
    let sessionInfo = null;
    let peerConnection = null;
    let pendingFormRequest = false;
    let pendingStopAvatarRequest = false;
    let initialPageLoad = true;
    const language = document.documentElement.lang;
    
    const dmAIChat = document.querySelector(".dm-ai-chat");

    if(dmAIChat) {
        // DOM Elements
        const form = document.querySelector(".dm-ai-form");
        const errorContainer = form.closest(".dm-ai-chat__form").querySelector(".chat-error");
        const mediaElement = form.closest(".dm-ai-chat").querySelector(".js-avatar-video");
        const taskInput = form.querySelector(".js-task-input");
        const apiBaseUrl = form.getAttribute("data-url");
        const submitButton = form.querySelector(".js-talk-button");
        const loadingSpinner = form.closest(".dm-ai-chat").querySelector(".loader-container");
        const audio = form.closest(".dm-hero--ai").querySelector("audio");
        const stopAvatarButton = form.closest(".dm-ai-chat").querySelector(".js-stop-avatar");
        const greetingVideo = dmAIChat.querySelector(".js-greeting-video");
        const showPromptsButton = dmAIChat.querySelector(".js-show-prompts");
        const hidePromptsButton = dmAIChat.querySelector(".js-hide-prompts");
        const campaignName = document.querySelector(".dm-hero--ai").getAttribute("data-campaign") || "";
        const messageContainer = form.closest(".dm-ai-chat").querySelector(".chat-message");
        let inputText = "";
        
        let disableFormTimeout;
        let avatarIsLoaded = false;
        let chatAiEndpoint;
        campaignName === "Unboxing2025" ? chatAiEndpoint = `${apiBaseUrl}/api/ai/heygen/chat-ai/chat-ai-${campaignName.toLowerCase()}/` : chatAiEndpoint = `${apiBaseUrl}/api/ai/heygen/chat-ai/chat-ai/`;
        const template = document.querySelector("#ai-overlay").content.cloneNode(true);
        const body = document.querySelector("body");
        body.appendChild(template);
        
        // Get video with unique identifier
        const params = new URLSearchParams(window.location.search);
        const clientHash = params?.get("ch")?.replace(/(^[^\wäöüß-]+|[^\wäöüß-]+$|\b[^\wäöüß-]+\b)/g, ' ').trim();
        const hashIdentifier = clientHash || "";
        
        async function createNewSession() {

            try {
                // Initialize streaming session
                const response = await fetch(`${apiBaseUrl}/api/ai/heygen/new-session/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        campaign: campaignName
                    }),
                });
                
                if(!response.ok) {
                    throw new Error("Something went wrong when getting session. Try again later.");
                }

                const data = await response.json();

                sessionInfo = data;
                
                // Setup WebRTC peer connection
                const { sdp: serverSdp, ice_servers2: iceServers } = sessionInfo;
                
                // Initialize with ICE servers for NAT traversal
                peerConnection = new RTCPeerConnection({ iceServers });
                
                // Configure media handling
                peerConnection.ontrack = (event) => {
                    // Attach incoming media stream to video element
                    if (event.track.kind === 'audio' || event.track.kind === 'video') {
                        mediaElement.srcObject = event.streams[0];
                    }
                };
                
                // Set server's SDP offer
                await peerConnection.setRemoteDescription(new RTCSessionDescription({type: 'offer', sdp: serverSdp}));
                
            } catch (error) {
                console.log(error)
                handleShowingErrorContainer();
                loadingSpinner.classList.remove("d-block");
            }
        }

        // Handle ICE candidates
        async function handleICE(session_id, candidateObj) {
            const { candidate, sdpMLineIndex, sdpMid, usernameFragment } = candidateObj;
            
            try {
                const response = await fetch(`${apiBaseUrl}/api/ai/heygen/submit-ice/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        session_id,
                        candidate,
                        sdp_mline_index: sdpMLineIndex,
                        sdp_mid: sdpMid,
                        username_fragment: usernameFragment || ""
                    }),
                });
                
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
            } catch (error) {
                console.error('ICE candidate error:', error);
            } 
            
        }

        // Start streaming session
        async function startStreamingSession(e) {
            
            // Create and set local description
            loadingSpinner.classList.add("d-block");
            await createNewSession();

            const localDescription = await peerConnection.createAnswer();
            const { sdp, type } = localDescription;
            
            await peerConnection.setLocalDescription(localDescription);

            // Handle ICE candidates
            peerConnection.onicecandidate = ({ candidate }) => {
                if (candidate) {
                    handleICE(sessionInfo.session_id, candidate.toJSON());
                }
            };

            // Monitor connection state changes
            peerConnection.oniceconnectionstatechange = () => {
                
                if(peerConnection.iceConnectionState === "disconnected") {
                    closeSession();
                } else if(peerConnection.iceConnectionState === "connected") {
                    window.dataLayer.push({
                        "event": "select_content",
                        "event_id": 1.5,
                        "language": language,
                        "campaign": campaignName
                    });
                } 
            };

            // Start streaming
            try {
                const startResponse = await fetch(`${apiBaseUrl}/api/ai/heygen/start-session/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        session_id: sessionInfo.session_id,
                        sdp_data: sdp,
                        sdp_type: type
                    }),
                });
                
                const data = await startResponse.json();
                
                if(!startResponse.ok) {
                    throw new Error(`${data.detail}. Try again later.` || "Something went wrong when getting starting streaming");
                }

                avatarIsLoaded = true;

            } catch (error) {
                console.log(error)
                handleShowingErrorContainer();
            } finally {
                loadingSpinner.classList.remove("d-block");
                
                
                if(avatarIsLoaded) {

                    showAvatarInputField();
                    submitButton.removeAttribute("disabled");
                    
                    if(initialPageLoad && campaignName === "Unboxing2025") {
                        showPromptsButton.classList.remove("d-none");
                        showPromptsButton.classList.add("fade-in");

                        sendText(e);
                    }  
                }
            }
        }
        
        // Send text to avatar
        async function sendText(e, promptValue) {
            e.preventDefault();
            
            if (pendingFormRequest) return;

            if(errorContainer.classList.contains("d-block")) {
                errorContainer.classList.remove("d-block");
            }

            if (!sessionInfo) {
                errorContainer.classList.add("d-block");
                return;
            }

            if(campaignName === "Unboxing2025") {
                handleUnboxingCampaignInput(promptValue);
            } else {
                inputText = sanitizeInput(promptValue || taskInput.value);
            }

            if(inputText === "") {
                return;
            }
            

            if(campaignName !== "Unboxing2025") {
                messageContainer.classList.remove("d-block")
                updateContent(messageContainer, "p", inputText);
                messageContainer.classList.add("d-block");
            }
            
            
            submitButton.setAttribute('disabled', 'true');
            pendingFormRequest = true;
            loadingSpinner.classList.add("d-block");         

            try {
                const response = await fetch(`${chatAiEndpoint}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        session_id: sessionInfo.session_id,
                        text: inputText,
                        identifier: hashIdentifier,
                        language: language
                    }),
                });
                
                taskInput.value = "";
                const textResponse = await response.json();

                if (!response.ok) {
                    throw new Error(`${textResponse.detail}. Try again later.` || `HTTP error! status: ${response.status}`);
                }

                if(campaignName === "Unboxing2025") {
                    updateContent(document.querySelector(".js-chat-prompt--1"), "span", textResponse.prompt1);
                    updateContent(document.querySelector(".js-chat-prompt--2"), "span", textResponse.prompt2);
                    updateContent(document.querySelector(".js-chat-prompt--3"), "span", textResponse.prompt3);
                }
                
                
                // Send text to avatar to speak
                const heygenResponse = await fetch(`${apiBaseUrl}/api/ai/heygen/send-task/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        session_id: sessionInfo.session_id,
                        text: textResponse.response,
                    }),
                });

                if(stopAvatarButton.classList.contains("d-none")) {
                    stopAvatarButton.classList.remove("d-none");
                    stopAvatarButton.classList.add("fade-in");
                }

                stopAvatarButton.removeAttribute("disabled");
                loadingSpinner.classList.remove("d-block");
                const heygenFetchResponse = await heygenResponse.json();

                if (!heygenResponse.ok) {
                    throw new Error(`${heygenFetchResponse.detail}. Try again later.` || `HTTP error! status: ${heygenResponse.status}`);
                }

                const messageDuration = heygenFetchResponse.response?.data?.duration_ms || 0;

                disableFormTimeout = setTimeout(() => {
                    submitButton.removeAttribute("disabled");
                    stopAvatarButton.setAttribute('disabled', 'true');
                    pendingFormRequest = false;

                    showAvatarInputField();

                    // show prompts after avatar is done speaking
                    if(campaignName === "Unboxing2025") {
                        
                        showPromptsButton.classList.remove("d-none");
                        const buttonsToggleTimeout = setTimeout(() => {
                            showPromptsButton.classList.remove("hide");
                            clearTimeout(buttonsToggleTimeout);
                        }, 200);
                    }
                    messageContainer.classList.remove("d-block");

                    clearTimeout(disableFormTimeout);
                }, messageDuration);
                
                
            } catch (error) {
                console.log(error);
                pendingFormRequest = false;
                loadingSpinner.classList.remove("d-block");

                handleShowingErrorContainer();
            }
        }

        async function closeSession() {
            if (!sessionInfo) {
                return;
            }

            try {
                await fetch(`${apiBaseUrl}/api/ai/heygen/close-session/`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        session_id: sessionInfo.session_id
                    }),
                });
                
                if (peerConnection) {
                    peerConnection.close();
                    peerConnection = null;
                }
                
                sessionInfo = null;
                mediaElement.srcObject = null;
                avatarIsLoaded = false;
                initialPageLoad = false;
                
                
            } catch (error) {
                console.log(error)
            } finally {
                loadingSpinner.classList.remove("d-block");
                submitButton.removeAttribute('disabled');

                form.classList.add("d-none");
                stopAvatarButton.classList.remove("fade-in");
                stopAvatarButton.classList.add("d-none");
                updateContent(form.closest(".dm-ai-chat"), ".chat-message p", "");
                form.closest(".dm-ai-chat").querySelector(".chat-message").classList.remove("d-block");

                dmAIChat.querySelector(".dm-ai-chat__play-overlay").classList.remove("d-none");
                

                if(greetingVideo) {
                    // Show starting video again

                    greetingVideo.classList.remove("d-none");
                    greetingVideo.currentTime = 0;
    
                    const hideOverlayTimeout = setTimeout(() => {
                        dmAIChat.querySelector(".dm-ai-chat__play-overlay").classList.remove("hide");
                        greetingVideo.classList.remove("hide");
                        clearTimeout(hideOverlayTimeout);
                    }, 300);
                } else {
                    // Hide prompts and prompts button
                    if(campaignName === "Unboxing2025") {
                        hidePrompts();
                        showPromptsButton.classList.add("hide");
                        const buttonsToggleTimeout = setTimeout(() => {
                            showPromptsButton.classList.add("d-none");
                            clearTimeout(buttonsToggleTimeout);
                        }, 400);

                        dmAIChat.querySelector(".dm-ai-chat__play-overlay").classList.remove("d-none");
    
                        const hideOverlayTimeout = setTimeout(() => {
                            dmAIChat.querySelector(".dm-ai-chat__play-overlay").classList.remove("hide");
                            clearTimeout(hideOverlayTimeout);
                        }, 300);
                    }
                }
            }   
        }

        async function stopAvatar() {

            if(greetingVideo && (greetingVideo.currentTime > 0 && !greetingVideo.paused)) {
                greetingVideo.pause();
                greetingVideo.classList.add("hide");

                const hideVideoTimeout = setTimeout(() => {
                    greetingVideo.classList.add("d-none");
                    clearTimeout(hideVideoTimeout);
                }, 300);

                stopAvatarButton.classList.remove("fade-in");
                stopAvatarButton.setAttribute("disabled", "true");
                stopAvatarButton.classList.add("d-none");

                window.dataLayer.push({
                    "event": "select_content",
                    "event_id": 1.6
                });

                await startStreamingSession();
                return;
            }

            if (pendingStopAvatarRequest) return;
            pendingStopAvatarRequest = true;

            clearTimeout(disableFormTimeout);
            loadingSpinner.classList.add("d-block");

            try {
                const response = await fetch(`${apiBaseUrl}/api/ai/heygen/interrupt-task/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        session_id: sessionInfo.session_id
                    }),
                });

                const stopTaskResponse = await response.json();

                if (!response.ok) {
                    throw new Error(`${stopTaskResponse.detail}` || `Looks like something went wrong with request`);
                }

                submitButton.removeAttribute("disabled");

                window.dataLayer.push({
                    "event": "select_content",
                    "event_id": 1.6,
                    "language": language,
                    "campaign": campaignName
                });

            } catch (error) {
                console.log(error);
                handleShowingErrorContainer();
                
            } finally {
                pendingFormRequest = false;
                pendingStopAvatarRequest = false;
                stopAvatarButton.setAttribute('disabled', 'true');
                loadingSpinner.classList.remove("d-block");
                showAvatarInputField();

                messageContainer.classList.remove("d-block")

                if(campaignName === "Unboxing2025") {
                    showPromptsButton.classList.remove("d-none");
                    const buttonsToggleTimeout = setTimeout(() => {
                        showPromptsButton.classList.remove("hide");
                        clearTimeout(buttonsToggleTimeout);
                    }, 300);
                }
            }
        }
            
        form.addEventListener("submit", sendText);
        stopAvatarButton.addEventListener("click", stopAvatar);
 
        dmAIChat.querySelector(".dm-ai-chat__play-overlay").addEventListener("click", (e) => {
            
            if(audio) {
                audio.volume = 0.06;
                audio.play();
            }

            if(greetingVideo) {
                dmAIChat.querySelector(".js-greeting-video").play();
                window.dataLayer.push({
                    "event": "select_content",
                    "event_id": 1.7,
                    "language": language,
                    "campaign": campaignName
                });
            } else {
                dmAIChat.querySelector(".dm-ai-chat__play-overlay").classList.add("hide");
    
                const hideOverlayTimeout = setTimeout(() => {
                    dmAIChat.querySelector(".dm-ai-chat__play-overlay").classList.add("d-none");
                    clearTimeout(hideOverlayTimeout);
                }, 300);

                // start avatar
                startStreamingSession(e);
            }
            
            
        });

        async function fetchVideoLink() {
            if(hashIdentifier !== "") {
                try {
                    const getVideoLink = await fetch(`${apiBaseUrl}/api/ai/heygen/chat-ai/get-link/`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            identifier: hashIdentifier
                        }),
                    });
    
                    if(!getVideoLink.ok) {
                        throw new Error(`Could not get video link`);
                    }
    
                    const getVideoLinkResponse = await getVideoLink.json();
    
                    if(getVideoLinkResponse.link_url) {
                        greetingVideo.src = getVideoLinkResponse.link_url;
                    }

                } catch (error) {
                    console.log(error);
                }
            }

        }

        if(greetingVideo) {
            fetchVideoLink();
    
            greetingVideo.addEventListener("playing", () => {
                dmAIChat.querySelector(".dm-ai-chat__play-overlay").classList.add("hide");
    
                const hideOverlayTimeout = setTimeout(() => {
                    dmAIChat.querySelector(".dm-ai-chat__play-overlay").classList.add("d-none");
                    clearTimeout(hideOverlayTimeout);
                }, 300);
    
                stopAvatarButton.removeAttribute("disabled");
                stopAvatarButton.classList.remove("d-none");
                stopAvatarButton.classList.add("fade-in");
    
            });
    
            greetingVideo.addEventListener("ended", () => {
                greetingVideo.classList.add("hide");
    
                const hideVideoTimeout = setTimeout(() => {
                    greetingVideo.classList.add("d-none");
                    clearTimeout(hideVideoTimeout);
                }, 300);
    
                stopAvatarButton.classList.remove("fade-in");
                stopAvatarButton.setAttribute("disabled", "true");
                stopAvatarButton.classList.add("d-none");
                startStreamingSession();
            });
    
            greetingVideo.addEventListener("error", () => {
                const defaultVideoSrc = greetingVideo.getAttribute("data-default-video");
                greetingVideo.src = defaultVideoSrc;
                // greetingVideo.play();
            });
        } else {

            const overlay = document.querySelector(".dm-ai-chat__overlay");

            document.querySelector(".js-download").addEventListener("click", () => {
                
                const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
                document.documentElement.style.setProperty('--scrollbar-width', `${scrollbarWidth}px`);

                if(!clientHash) {
                    overlay.classList.remove("d-none");
                    
                    const showOverlayTimeout = setTimeout(() => {
                        overlay.classList.add("show-opacity");
                        clearTimeout(showOverlayTimeout);
                    }, 300);

                    document.querySelector("html").classList.add("overflow-hidden");

                    window.dataLayer.push({
                        "event": "select_content",
                        "event_id": 1.8,
                        "language": language,
                        "campaign": campaignName,
                        "event_status": "Newsletter"
                    });

                } else {
                    const pdfLink = document.querySelector(".dm-hero--ai").getAttribute("data-file");
                    const pdfFilename = document.querySelector(".dm-hero--ai").getAttribute("data-filename");
                    // Create a temporary link element
                    const link = document.createElement("a");
                    link.href = pdfLink;
                    link.download = pdfFilename;
                    link.click();

                    window.dataLayer.push({
                        "event": "select_content",
                        "event_id": 1.8,
                        "language": language,
                        "campaign": campaignName,
                        "event_status": "Download"
                    });
                }
                
            });

            overlay.querySelector(".js-close-overlay").addEventListener("click", () => {
                overlay.classList.remove("show-opacity");
                const hideOverlayTimeout = setTimeout(() => {
                    overlay.classList.add("d-none");
                    clearTimeout(hideOverlayTimeout);
                }, 300);
                if(overlay.querySelector("form")) {
                    const overlayForm = overlay.querySelector("form");
                    overlayForm.reset();
                    overlayForm.querySelectorAll(".error").forEach(input => input.classList.remove("error"));
                }
                document.querySelector("html").classList.remove("overflow-hidden");
            });
        }

        // Handle chat prompts
        if(showPromptsButton) {
            const prompts = form.closest(".dm-ai-chat").querySelectorAll(".chat-prompt");
            showPromptsButton.addEventListener("click", showPrompts);
            hidePromptsButton.addEventListener("click", hidePrompts);
            prompts.forEach((item, i) => item.addEventListener("click", (e) => {
                window.dataLayer.push({
                    "event": "select_content",
                    "event_id": 1.11,
                    "language": language,
                    "campaign": campaignName,
                    "event_status": `Prompt${i + 1}`
                });
                sendText(e, item.querySelector("span").innerHTML);
            }));
        }

        // HELPER FUNCTIONS
        function showPrompts() {
            showPromptsButton.classList.add("d-none");
            hidePromptsButton.classList.add("d-block");
        
            const buttonsToggleTimeout = setTimeout(() => {
                hidePromptsButton.classList.add("show-opacity");
                clearTimeout(buttonsToggleTimeout);
            }, 200);
        
            // Show all prompts with staggered animations
            const prompts = form.closest(".dm-ai-chat").querySelector(".chat-prompts-container");
            prompts.classList.add("show");
            window.dataLayer.push({
                "event": "select_content",
                "event_id": 1.12,
                "language": language,
                "campaign": campaignName
            });
        }

        function hidePrompts() {
            showPromptsButton.classList.remove("d-none");
            hidePromptsButton.classList.remove("show-opacity");
            hidePromptsButton.classList.remove("d-block");
            const prompts = form.closest(".dm-ai-chat").querySelector(".chat-prompts-container");
            prompts.classList.remove("show");
        }

        function updateContent(context, selector, content) {
            const element = context.querySelector(selector);
            element.innerHTML = content;
        }

        function showAvatarInputField() {
            if(form.classList.contains("d-none")) {
                form.classList.remove("d-none");
                form.classList.add("fade-in");
            }
        }

        function handleShowingErrorContainer() {
            if(!errorContainer.classList.contains("d-block")) {
                errorContainer.classList.add("d-block");
            } else {
                errorContainer.classList.remove("d-block");
            }
        }

        function sanitizeInput(input) {
            return input.replace(/(^[^\wäöüß]+|[^\wäöüß]+$|\b[^\wäöüß]+\b)/g, ' ').trim();
        }

        function handleUnboxingCampaignInput(promptValue) {
            
            if(initialPageLoad) {
                inputText = "Hi, write me an introduction";
                initialPageLoad = false;
            } else {
                // set input text to either prompt value or input field value
                inputText = sanitizeInput(promptValue || taskInput.value);

                if(messageContainer.classList.contains("d-block")) {
                    messageContainer.classList.remove("d-block")
                }

                updateContent(messageContainer, "p", inputText);
                messageContainer.classList.add("d-block");
            }

            hidePrompts();
            showPromptsButton.classList.add("d-none");
        }
    }

   
}